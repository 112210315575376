var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"class-hour-management flex items-center flex-col",staticStyle:{"display":"flex","align-items":"center","flex-direction":"column","padding":"80px 0 0 0 !important"}},[_c('div',{staticClass:"title wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"2s"}},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{staticClass:"desc wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"4s"}},[_vm._v(" "+_vm._s(_vm.content.desc)+" ")]),_c('div',{staticClass:"btn wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"6s"}},[_c('v-btn',{staticClass:"mt-10",style:({
          'background-color': _vm.content.bottomColor,
          'border-color': _vm.content.bottomColor,
        }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.goto('https://66.training/admin/login')}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("field.login"))+" ")])])],1),_c('div',{staticClass:"btn wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"6s"}},[_c('v-btn',{staticClass:"mt-5",style:({
          'background-color': _vm.content.bottomColor,
          'border-color': _vm.content.bottomColor,
        }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.jumbLeave()}}},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.content.btnText)+" ")]),_c('v-img',{attrs:{"src":_vm.content.btnDirection}})],1)],1),_c('img',{staticClass:"w-0",attrs:{"src":_vm.content.coverImg}})])
}
var staticRenderFns = []

export { render, staticRenderFns }