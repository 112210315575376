<template>
  <!-- 目前在用的是这个底部 -->
  <v-footer dark padless style="background-color: #334050">
    <v-row align="center" justify="start">
      <v-col md="12" sm="6" align-self="start" class="footer-area">
        <v-row>
          <v-col cols="12">
            <div class="flex">
              <div>
                <v-img src="@/assets/img/orange-logo.svg" max-width="35px" />
              </div>
              <div class="flex" style="margin-left: 20px; font-size: 25px">
                {{ $t("field.lelePlatform") }}
              </div>
            </div>
          </v-col>

          <v-col md="4" sm="10">
            <div style="margin-top: 15px" class="apply-now">
              <div>
                <div class="apply-now-title">
                  {{ $t("content.applyNow") }}
                </div>
                <div class="mt15 belong-title">
                  {{ $t("content.belongsYourInstitution") }}
                </div>

                <div class="mt15 apply-now-btn-area">
                  <div html-type="submit" style="display: flex; justify-content: start">
                    <a-button class="submit-button" @click="goToAppointment()">
                      {{ $t("content.appointmentDemonstration") }}
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="8" sm="10">
            <div>
              <div class="mt15 sub-title">
                {{ $t("content.aboutUs") }}
              </div>
              <div class="mt15">
                {{ $t("content.platformPage") }}
              </div>
              <div class="mt15">
                <a href="https://www.facebook.com/profile.php?id=61557282451201&mibextid=LQQJ4d" target="_blank">
                  <v-img src="@/assets/img/platform.png" max-width="350px" />
                </a>
              </div>
              <div class=" " style="margin-top: 3px">
                <v-row align="center" align-self="start" class="content mt15">
                  <v-col cols="12" md="3" sm="6" style="padding: 0" class="white--text text-left the-svg-area">
                    <div class="content-content flex items-center justify-start">
                      <div>
                        <v-img src="~@/assets/footer/foot-phone.svg" class="small-svg" />
                      </div>
                      <div class="content-content">&nbsp; (852) 6148 6870</div>
                    </div>
                  </v-col>
                  <span class="vertical-line mr-5"> | </span>
                  <v-col cols="12" md="3" sm="6" style="padding: 0" class="white--text text-left the-svg-area">
                    <div class="content-content flex items-center justify-start">
                      <div>
                        <v-img src="~@/assets/footer/foot-phone.svg" class="small-svg" />
                      </div>
                      <div class="content-content">&nbsp; (853) 6666 1644</div>
                    </div>
                  </v-col>
                  <span class="vertical-line mr-5"> | </span>

                  <v-col cols="12" md="3" sm="6" style="padding: 0" class="white--text text-left the-svg-area">
                    <div class="content-content flex items-center justify-start">
                      <div>
                        <v-img src="~@/assets/footer/foot-wechat.svg" class="small-svg" />
                      </div>
                      <div class="content-content">&nbsp; LuckTraining</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row align="center" class="content mt15">
                  <v-col cols="12" md="3" sm="6" style="padding: 0" class="white--text text-left the-svg-area">
                    <div class="content-content flex items-center justify-start">
                      <div>
                        <v-img src="~@/assets/footer/foot-internett.svg" class="small-svg" />
                      </div>
                      <div class="content-content">&nbsp; www.66.training</div>
                    </div>
                  </v-col>
                  <span class="vertical-line mr-5"> | </span>

                  <v-col cols="12" md="4" sm="6" style="padding: 0" class="white--text text-left the-svg-area">
                    <div class="content-content flex items-center justify-start">
                      <div>
                        <v-img src="~@/assets/footer/foot-email.svg" class="small-svg" />
                      </div>
                      <div class="content-content">&nbsp; info@66.training</div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <hr />
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              Copyright &copy; 2024 ，
              <span style="color: orange"> LUCK PLATFORM</span> TECHNOLOGY LIMITED all rights reserved .
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <download-dialog :openDownloadDialog="openDownloadDialog" @close="openDownloadDialog = false"></download-dialog>
  </v-footer>
</template>
<script setup>
import { localeList } from "@/settings/localeSetting";
import { downloadList, getMobileOperatingSystem } from "@/hooks/web/userAgent.js";
import downloadDialog from "@/components/common/downloadDialog.vue";
import "mutationobserver-shim";
</script>
<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    select: [],
    isScrolling: false,
    scrollPosition: 0,
    openDownloadDialog: false,
  }),
  watch: {},
  props: {},
  methods: {
    gotoNew(url) {
      window.open(url, "_blank");
    },
    ifHome() {
      this.gotoNew("https://66.training/admin/register");
    },
    openDownload() {
      this.openDownloadDialog = true;
    },
    goToAppointment() {
      this.$router.push({
        path: "/leave_message",
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped>
.v-card {
  width: 100%;
}
.svg-border-waves .v-image {
  position: absolute;
  top: -48px;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.row {
  margin: 0;
}
.small-svg {
  width: 20px;
  /* margin-top: 10px; */
}
.content-content {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.v-application .mt-6 {
  margin-top: 0 !important;
}

.flex {
  display: flex;
  align-items: center;
}
.content-content-new {
  display: flex;
  align-items: center;
}

.mt15 {
  margin-top: 15px;
}
.sub-title {
  font-family: HiraginoSansGB, HiraginoSansGB;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  line-height: 27px;
  text-align: left;
  font-style: normal;
}
.the-svg-area {
  display: flex;
  align-items: center;
  justify-content: start;
}
@media (max-width: 960px) {
  .the-svg-area {
    padding: 0;
  }
  .footer-area {
    /* padding: 20px; */
    min-height: 150px;
  }
  .vertical-line {
    display: none;
  }
  .apply-now,
  .apply-now-btn-area {
    display: flex;
    justify-content: center;
  }
  .apply-now-title {
    display: flex;
    justify-content: center;
    font-family: MicrosoftYaHei;
    font-size: 25px;
    color: white;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 20px;
    color: white;
    text-align: left;
    font-style: normal;
  }
}
@media (min-width: 959.999px) {
  .footer-area {
    padding: 20px 12%;
    min-height: 150px;
  }

  .vertical-line {
    display: flex;
  }
  .apply-now-title {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: white;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 14px;
    color: white;
    text-align: left;
    font-style: normal;
  }
}
.submit-button {
  min-width: 100px;
  padding: 0 30px;
  height: 40px;
  background: #fe880a;
  box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
  border-radius: 33px;
  font-size: 18px;
  color: #ffffff;
  border: none;

  /* &:after {
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
  } */
}
</style>
