<template>
  <v-app>
    <!-- 主页 乐乐培训 -->
    <navigation :flat="true" />
    <v-main class="pt-0">
      <training-phone :content="coverPhoneData" v-if="isShowPhone"></training-phone>
      <cover-view :data="coverData" v-if="!isShowPhone" />
      <div class="video-exhibit">
        <div class="text-content">
          <div>
            <h1>{{ $t("field.leleTrainingTwo") }}</h1>
            <p>{{ $t("content.teachingPlatform") }}</p>
            <p style="display: flex; align-items: center">
              {{ $t("content.learnMore") }}
              <v-img src="@/assets/img/video-right.svg" style="margin-left: 10px" contain max-width="25px" />
            </p>
          </div>
        </div>
        <div class="video-play">
          <video
            ref="videoExhibitRef"
            src="https://66.training/training_read_minio/other/171c1c8c-84d3-450a-87ff-a03ed8e91585.mp4"
            controls
            @play="changeVideoPlayShow(false)"
            @pause="changeVideoPlayShow(true)"
          />
          <!-- <svg-icon
            v-show="videoPlayShow"
            icon-class="video-play"
            @click="handlePlayVideo"
          /> -->
        </div>
      </div>
      <desc-content-list :content="descContent"></desc-content-list>
      <!-- 線上課程 -->
      <content-img
        :image="require('@/assets/img/online-course-illustration.png')"
        :title="$t('online.onlineCourses')"
        direction="LTR"
        :coverColor="'#f6faf7'"
        :alignValue="'center'"
        :features="[
          {
            content: $t('online.onlineAndOfflineDualLineCourses'),
            icon: require('@/assets/img/online-offline.svg'),
          },
          {
            content: $t('online.maximizingTeacherResources'),
            icon: require('@/assets/img/maximum-utilization.svg'),
          },
          {
            content: $t('online.teachingVideoRetention'),
            icon: require('@/assets/img/retention.svg'),
          },
        ]"
      ></content-img>

      <!-- 視頻課程 -->
      <content-img
        :image="require('@/assets/img/video-courses.png')"
        :title="$t('online.videoCourse')"
        direction="RTL"
        :features="[
          {
            content: $t('online.videoCourseHelp'),
            icon: require('@/assets/img/videoCourseHelp.svg'),
          },
          {
            content: $t('online.packagingCombination'),
            icon: require('@/assets/img/packagingCombination.svg'),
          },
          {
            content: $t('online.supportOpenness'),
            icon: require('@/assets/img/supportOpenness.svg'),
          },
        ]"
      ></content-img>

      <content-img
        :image="require('@/assets/img/train-student-management.png')"
        :title="$t('field.studentManagement')"
        :descs="[$t('content.studentsGenerateIndependentFiles')]"
        animation-entry="animate__fadeIn"
        direction="LTR"
        :features="[]"
        :coverColor="'#F3F9F5'"
      ></content-img>
      <content-img
        :image="require('@/assets/img/train-communication.png')"
        :title="$t('field.communication')"
        :descs="[$t('content.efficientCommunication')]"
        direction="RTL"
        :features="[
          {
            title: $t('field.instantMessaging'),
            content: $t('content.privateCommunicationTools'),
            icon: require('@/assets/img/train-communication-chat.svg'),
          },
          {
            title: $t('field.informationRelease'),
            content: $t('content.inChatGroups'),
            icon: require('@/assets/img/train-communication-notice.svg'),
          },
          {
            title: $t('field.studentsTakingLeave'),
            content: $t('content.leaveInformationIsNotSynchronized'),
            icon: require('@/assets/img/train-communication-leave.svg'),
          },
        ]"
      ></content-img>
      <content-img
        :image="require('@/assets/img/train-paySetting.png')"
        :title="$t('field.teacherFeeSetting')"
        :descs="[$t('content.salarySituation')]"
        direction="LTR"
        :coverColor="'#F3F9F5'"
        :features="[
          {
            content: $t('content.setType'),
            icon: require('@/assets/img/train-paySetting-money.svg'),
          },
          {
            content: $t('content.billingMethod'),
            icon: require('@/assets/img/train-paySetting-person.svg'),
          },
          {
            content: $t('content.attendanceRecord'),
            icon: require('@/assets/img/train-paySetting-calendar.svg'),
          },
        ]"
      ></content-img>

      <content-img
        :image="require('@/assets/img/train-finance.png')"
        :title="$t('field.financialFunctions')"
        direction="RTL"
        :features="[
          {
            content: $t('content.costSituation'),
            icon: require('@/assets/img/train-finance-icon1.svg'),
          },
          {
            content: $t('content.throughDataStatisticsAndAnalysis'),
            icon: require('@/assets/img/train-finance-icon2.svg'),
          },
        ]"
      ></content-img>
      <!-- 私有云服务 -->
      <content-img
        style="padding: 40px 0"
        :image="require('@/assets/img/video-courses.png')"
        :title="$t('online.privateCloudServicePlan')"
        direction="LTR"
        :coverColor="'#F3F9F5'"
        :features="[
          {
            content: $t('online.helpBuild'),
            icon: require('@/assets/img/helpBuild.svg'),
          },
          {
            content: $t('online.enableInstitutionsTo'),
            icon: require('@/assets/img/enableInstitutionsTo.svg'),
          },
          {
            content: $t('online.supportOpenness'),
            icon: require('@/assets/img/macauTelecomCloudServices.svg'),
          },
        ]"
      ></content-img>
      <!-- 机关老板APP -->
      <content-img
        :image="require('@/assets/img/train-admin.png')"
        :title="$t('field.administratorApp')"
        :descs="[$t('content.importantInformation')]"
        direction="RTL"
        :coverColor="'#ffffff'"
        :features="[
          {
            content: $t('content.controllableAndTraceable'),
            icon: require('@/assets/img/train-admin-icon1.svg'),
          },
          {
            content: $t('content.provideDataSupport'),
            icon: require('@/assets/img/train-admin-icon2.svg'),
          },
          {
            content: $t('content.improveTheQualityOfInstitutionalServices'),
            icon: require('@/assets/img/train-admin-icon3.svg'),
          },
        ]"
      ></content-img>
      <!-- 機構學員APP -->
      <desc-content-list :content="vxContent"></desc-content-list>
      <customization-area></customization-area>

      <!-- 套餐 -->
      <!-- <package-list
        :title="$t('online.yourNeedsSelection')"
        :subTitle="$t('online.yourNeedsSelection')"
        :descs="[$t('online.chooseTheMostSuitable')]"
        :features="[
          {
            title: $t('online.advancedVersion'),
            titleColor: '#376076',
            content: $t('online.availableCloudSpace'),
            time: $t('content.month'),
            num: 1,
            mop: 189,
            platformFees: 2,
            icon: require('@/assets/img/good-helper-01.svg'),
            descColor: '#d6f1ff',
            iconSize: '50px',
            duration: 2,
            desc: [
              {
                descIcon: require('@/assets/img/good-blue.svg'),
                content: 10 + $t('online.mentors'),
              },
              {
                descIcon: require('@/assets/img/good-blue.svg'),
                content: 1 + $t('online.auxiliaryManagerPermissions'),
              },
              {
                descIcon: require('@/assets/img/good-blue.svg'),
                content: 30 + $t('online.classOpeningPermissions'),
              },
            ],
          },
          {
            title: $t('online.supremeEdition') + '（C1）',
            titleColor: '#4932CB',
            content: $t('online.availableCloudSpace'),
            time: $t('content.month'),
            num: 3,
            mop: 499,
            platformFees: 0,
            icon: require('@/assets/img/good-helper-02.svg'),
            descColor: '#d8d2f7',
            iconSize: '50px',
            duration: 4,
            desc: [
              {
                descIcon: require('@/assets/img/good-purple.svg'),
                content: 30 + $t('online.mentors'),
              },
              {
                descIcon: require('@/assets/img/good-purple.svg'),
                content: 5 + $t('online.auxiliaryManagerPermissions'),
              },
              {
                descIcon: require('@/assets/img/good-purple.svg'),
                content: 100 + $t('online.classOpeningPermissions'),
              },
            ],
          },
          {
            title: $t('online.supremeEdition') + '+（C2）',
            titleColor: '#F7B500',
            content: $t('online.availableCloudSpace'),
            time: $t('content.month'),
            num: 10,
            mop: '1,149',
            platformFees: 0,
            icon: require('@/assets/img/good-helper-03.svg'),
            descColor: '#fef3d5',
            iconSize: '50px',
            duration: 6,
            desc: [
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 50 + $t('online.mentors'),
              },
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 5 + $t('online.auxiliaryManagerPermissions'),
              },
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 200 + $t('online.classOpeningPermissions'),
              },
            ],
          },
          {
            title: $t('online.supremeEdition') + '+（C2）',
            titleColor: '#FF8203',
            content: $t('online.availableCloudSpace'),
            time: $t('content.averageMonthly'),
            num: 10,
            mop: 899,
            platformFees: 0,
            icon: require('@/assets/img/good-helper-04.svg'),
            descColor: '#ffeedd',
            iconSize: '50px',
            duration: 8,
            serviceContract: 24 + $t('online.aMonth') + $t('online.serviceContract'),
            desc: [
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 50 + $t('online.mentors'),
              },
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 5 + $t('online.auxiliaryManagerPermissions'),
              },
              {
                descIcon: require('@/assets/img/good-yellow.svg'),
                content: 200 + $t('online.classOpeningPermissions'),
              },
            ],
          },
        ]"
      >
      </package-list> -->
      <!-- <profession style="padding-bottom: 100px"></profession> -->
    </v-main>
    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <foote />
  </v-app>
</template>

<script>
import navigation from "@/components/common/NavigationBar";
import trainingPhone from "./components/trainingPhone.vue";
import customizationArea from "./components/customizationArea";
import foote from "@/components/common/Footer01";
import coverView from "@/components/common/CoverView";
import contentImg from "@/components/common/contentImg";
import descContentList from "@/components/common/descContentList";
import descContentOdd from "@/components/common/descContentOdd";
import profession from "./components/profession.vue";
import packageList from "./components/packageList.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    navigation,
    coverView,
    contentImg,
    descContentOdd,
    foote,
    descContentList,
    profession,
    trainingPhone,
    packageList,
    customizationArea,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    isShowPhone: false,
    videoPlayShow: true,
    videoSwitch: true,
  }),
  computed: {
    coverData() {
      let _this = this;
      let coverData = {
        title: _this.$t("field.leleEducationAndTraining"),
        subTitle: _this.$t("content.customizedIntelligentManagementSystem"),
        btnText: _this.$t("content.appointmentDemonstration"),
        direction: require("@/assets/img/right-arrow.svg"),
        coverImg: require("@/assets/img/page-cover.png"),
        newImg: require("@/assets/img/page-img.png"),
        bottomColor: "#ff870d",
        bottomCover: "",
      };
      return coverData;
    },
    coverPhoneData() {
      let _this = this;
      let coverPhoneData = {
        title: _this.$t("field.leleEducationAndTraining"),
        desc: _this.$t("content.customizedIntelligentManagementSystem"),
        btnText: _this.$t("content.appointmentDemonstration"),
        btnDirection: require("@/assets/img/right-arrow.svg"),
        coverImg: require("@/assets/img/train-main.png"),
        bottomColor: "#ff870d",
      };
      return coverPhoneData;
    },
    descContent() {
      let _this = this;
      let descContent = {
        title: _this.$t("field.lessonManagement"),
        desc: _this.$t("content.simplerManagement"),
        coverImg: require("@/assets/img/class-hour-management.png"),
        features: [
          {
            icon: require("@/assets/img/color-course-type.svg"),
            title: _this.$t("content.flexibleCourseDesign"),
          },
          {
            icon: require("@/assets/img/color-automatic-statistics.svg"),
            title: _this.$t("content.automaticClassHourStatistics"),
          },
          {
            icon: require("@/assets/img/color-make-up-lessons.svg"),
            title: _this.$t("content.adjustmentAndMakeUpClasses"),
          },
          {
            icon: require("@/assets/img/color-remaining-class-hours.svg"),
            title: _this.$t("content.selfInspectionOfRemainingClassHours"),
          },
        ],
      };
      return descContent;
    },
    vxContent() {
      let _this = this;
      let vxContent = {
        title: _this.$t("field.trainingMiniProgram"),
        desc: _this.$t("content.exclusiveCustomMiniProgram"),
        coverImg: require("@/assets/img/train-vx.png"),
        features: [
          {
            icon: require("@/assets/img/train-vx-icon1.svg"),
            title: _this.$t("content.customizedMiniPrograms"),
          },
          {
            icon: require("@/assets/img/private-domain-space.svg"),
            title: _this.$t("content.usingExclusiveMiniPrograms"),
          },
          {
            icon: require("@/assets/img/view-calendar.svg"),
            title: _this.$t("content.viewCalendar"),
          },
          {
            icon: require("@/assets/img/view-published-ecourses.svg"),
            title: _this.$t("content.viewPublishedEcourses"),
          },
        ],
      };
      return vxContent;
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
    if (window.innerWidth < 650) {
      this.isShowPhone = true;
    } else {
      this.isShowPhone = false;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    changeVideoPlayShow(type) {
      this.videoSwitch = type;
    },
    handlePlayVideo() {
      this.$refs.videoExhibitRef.play();
    },
  },
  mounted() {
    // axios({
    //   method: "get",
    //   url: `https://66.training/training_api/base/base/company-package-level/getCompanyPacksListByDiffType`,
    //   // url: `http://192.168.0.254:9529/training_api/base/base/company-package-level/getCompanyPacksListByDiffType`,
    // }).then(function (response) {
    //   if (response.data.code == 200) {
    //     startCountdown();
    //     console.log(22323, response);
    //   }
    // });
  },
};
</script>

<style scoped lang="scss">
// * {
//   font-family: MicrosoftYaHei, MicrosoftYaHei !important;
// }
#box {
  width: 200px;
  height: 200px;
  background-color: red;
  position: absolute;
}
.v-main {
  /* background-image: url("~@/assets/img/bgMain.png"); */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
@media (max-width: 640px) {
  .video-exhibit {
    // @apply flex-col;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 38px 15px 70px;
    background-image: url("~@/assets/img/video-exhibit-bg.png");
    background-size: cover;

    .text-content {
      display: flex;
      justify-content: center;
      margin-right: 66px;
      width: 100%;

      h1 {
        display: flex;
        justify-content: center;
        font-size: 26px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2d3f50;
      }

      p {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #666666;
      }
      .v-image {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .video-play {
      margin-top: 38px;
      position: relative;

      video {
        height: 100%;
        width: 100%;
      }

      .svg-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        color: white;
        cursor: pointer;
      }
    }
  }
}
@media (min-width: 640px) {
  .video-exhibit {
    display: flex;
    justify-content: center;
    height: 750px;
    width: 100%;
    // padding-top: 200px;
    align-items: center;
    background-image: url("~@/assets/img/video-exhibit-bg.png");
    background-size: cover;

    .text-content {
      width: 552px;
      height: 440px;
      margin-right: 66px;
      // display: flex;

      h1 {
        font-size: 50px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: normal;
        color: #2d3f50;
      }

      p {
        margin-top: 30px;
        font-size: 30px;
        font-family: MicrosoftYaHei;
        color: #666666;
      }
      .v-image {
        width: 30px !important;
        height: 30px !important;
      }
    }

    .video-play {
      width: 782px;
      height: 440px;
      position: relative;

      video {
        height: 100%;
        width: 100%;
      }

      .svg-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        color: white;
        cursor: pointer;
      }
    }
  }
}
</style>
